<template>
  <router-view
    class="flex-grow"
    :class="
      $route.params.slug === 'topic' || $route.query.type === 'topic'
        ? 'bg-background-2'
        : 'bg-background-1'
    "
    :key="$route.params.slug"
  ></router-view>
</template>

<script>
import { computed, provide } from 'vue';
import { useRoute } from 'vue-router';

import _ from 'lodash';
export default {
  setup() {
    const route = useRoute();

    const titles = {
      topic: 'AI主題試卷',
      self: '自設試卷',
      past: '歷年試卷',
      sbj: '按科目練習',
    };

    const leading = {
      topic: '集結各科熱門主題!快利用篩選清單找出想練習的試卷吧!',
      self: '歡迎加入JODY，從這裡可以找到歷年考試的試卷喔!',
      past: '歡迎加入JODY，從這裡可以找到歷年考試的試卷喔!',
      sbj: '歡迎加入JODY，從這裡可以找到歷年考試的試卷喔!',
    };
    const pcBgs = {
      topic: 'banner02.png',
      self: 'banner02.png',
      past: 'banner01.png',
      sbj: 'banner03.png',
    };

    const mobileBgs = {
      topic: 'banner02_m.png',
      self: 'banner02_m.png',
      past: 'banner01_m.png',
      sbj: 'banner03_m.png',
    };

    const title = computed(() => {
      return titles[route.params.slug];
    });

    const pcBg = computed(() => {
      return pcBgs[route.params.slug];
    });

    const mobileBg = computed(() => {
      return mobileBgs[route.params.slug];
    });
    const breadcrumbs = computed(() => {
      const bcs = [];
      if (route.path.startsWith('/exam/self')) {
        bcs.push('QB題庫', '按試卷練習', title.value);
      } else if (route.path.startsWith('/exam/past')) {
        bcs.push('QB題庫', '按試卷練習', title.value);
      } else if (route.path.startsWith('/exam/sbj')) {
        bcs.push('QB題庫', '按科目練習');
      } else if (route.path.startsWith('/exam/topic')) {
        bcs.push('QB題庫', 'AI主題試卷');
      }
      return bcs;
    });

    provide('page', { breadcrumbs, title, leading, pcBg, mobileBg });

    return {};
  },
};
</script>
